import axios from 'axios'
import authAxios, {setGenevaAuthToken} from '@/lib/api/geneva/authAxios'
import {apiUrl, urlWithParams} from './util'
import {
    ApplicationUserLeaveInterface,
    ApplicationUserSearchResult,
    GenevaUserInterface,
    GenevaUserRoleInterface,
} from '@/interfaces/user_types'
import {NewApplicationUserSearchResultInterface} from '@/components/search/SupplierPersonSearchAutocomplete'
import {ApplicationUserLeavePeriodControllerInterface} from '@/interfaces/controller_types'
import {formatDateAsISO} from '@/utils/formatter'

export interface GenevaApplicationUserData {
    message: string
    application_user: GenevaUserInterface
}

interface TestTokenData {
    message: string
    access_token: string
}

export async function authenticateAuth0UserAgainstGeneva(auth0User: any, accessToken: string) {
    setGenevaAuthToken(accessToken)
    return await authAxios.post<GenevaApplicationUserData>(apiUrl('/api/authenticate'), auth0User)
}

interface ApplicationUserSaveResult {
    data: {
        message: string
        application_user: GenevaUserInterface
    }
}

export async function saveApplicationUser(application_user: GenevaUserInterface): Promise<ApplicationUserSaveResult> {
    return authAxios.post(`/api/users`, application_user)
}

export async function syncApplicationUserToAuth0(application_user_id: number): Promise<ApplicationUserSaveResult> {
    return authAxios.post(`/api/users/${application_user_id}/auth0_sync`, {})
}

export async function getApplicationUserForId(application_user_id: number): Promise<GenevaUserInterface> {
    const {data} = await authAxios.get(`/api/users/${application_user_id}`)
    return data.application_user
}

export async function getTestGenevaToken() {
    const email = 'vita@auntygrace.com.au'
    const params = {email: email}
    const userUrl = urlWithParams(apiUrl('/api/test_token'), params)
    const {data} = await axios.get<TestTokenData>(userUrl)
    return data.access_token
}

interface ApplicationUserRoleSaveResult {
    data: {
        message: string
        application_user_role: GenevaUserRoleInterface
    }
}

export async function saveApplicationUserRole(
    application_user_role: GenevaUserRoleInterface,
): Promise<ApplicationUserRoleSaveResult> {
    return authAxios.post(`/api/users/roles`, application_user_role)
}

interface ApplicationUserSearchResultSaveResult {
    data: {
        message: string
        application_user_search_result: ApplicationUserSearchResult
    }
}

export async function saveApplicationUserSearchResult(
    application_user_search_result: NewApplicationUserSearchResultInterface | ApplicationUserSearchResult,
): Promise<ApplicationUserSearchResultSaveResult> {
    return authAxios.post(`/api/application_user_search_results`, application_user_search_result)
}

interface ApplicationUsersResult {
    data: {
        message: string
        application_users: GenevaUserInterface[]
    }
}

export async function getApplicationUserSubordinates(application_user_id: number): Promise<ApplicationUsersResult> {
    return authAxios.get(`/api/users/${application_user_id}/subordinates`)
}

export async function getHomeCareSpecialists(): Promise<ApplicationUsersResult> {
    return authAxios.get('/api/users/home_care_specialist_application_users')
}

export async function getHomeCareSpecialistsField(): Promise<ApplicationUsersResult> {
    return authAxios.get('/api/users/home_care_specialist_field_application_users')
}

export async function getCareManagers(): Promise<ApplicationUsersResult> {
    return authAxios.get('/api/users/care_manager_application_users')
}

export async function getOperationsManagerCareManagementApplicationUsers(): Promise<ApplicationUsersResult> {
    return authAxios.get('/api/users/operation_manager_care_management_application_users')
}

interface ApplicationUserSearchResultsResult {
    data: {
        message: string
        application_user_search_results: ApplicationUserSearchResult[]
    }
}

export async function getLatestSearchResults(): Promise<ApplicationUserSearchResultsResult> {
    return authAxios.get('/api/users/search_results')
}

interface ApplicationUserLeaveSaveResult {
    data: {
        message: string
        application_user_leave: ApplicationUserLeaveInterface
    }
}

export async function saveApplicationUserLeave(
    application_user_leave: ApplicationUserLeaveInterface,
): Promise<ApplicationUserLeaveSaveResult> {
    return await authAxios.post('/api/users/leave', application_user_leave)
}

interface NewInterimReassignmentPeriodResult {
    data: {
        controller: ApplicationUserLeavePeriodControllerInterface
    }
}

export async function getNewInterimReassignmentPeriodController(
    application_user_id: number,
    from_date: Date,
    to_date: Date,
): Promise<NewInterimReassignmentPeriodResult> {
    return await authAxios.get(
        `/api/users/${application_user_id}/new_interim_reassignment_period_controller?from_date=${formatDateAsISO(
            from_date,
        )}&to_date=${formatDateAsISO(to_date)}`,
    )
}

export async function interimReassignmentPeriodEnd(controller: ApplicationUserLeavePeriodControllerInterface) {
    return await authAxios.post(`/api/users/interim_reassignment_period/end`, controller)
}

export async function interimReassignmentPeriodChangeDates(
    controller: ApplicationUserLeavePeriodControllerInterface,
    new_date: Date,
    col_name: string,
) {
    return await authAxios.post(
        `/api/users/interim_reassignment_period/change_dates?new_date=${formatDateAsISO(
            new_date,
        )}&col_name=${col_name}`,
        controller,
    )
}
